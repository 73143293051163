import React from 'react';

export const ContactPage = () => {

    return (
        <div className="container text-light mt-5">
            <div className="row">
                <h1 className="text-center">Contact me</h1>
                <hr/>
                <form acceptCharset='UTF-8'
                      action='https://app.formester.com/forms/ee9fbab8-9bff-4531-9fb0-2658cf86224f/submissions'
                      method='POST'>
                    <div className="mb-3">
                        <label htmlFor="emailInput" className="form-label">Email
                            address</label>
                        <input required type="email" name="_replyto" className="form-control" id="emailInput"
                               aria-describedby="emailHelp"/>
                        <div id="emailHelp" className="form-text text-muted">We'll never share your email with anyone
                            else.
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="exampleInputPassword1" className="form-label">Your Name</label>
                        <input required type="text" className="form-control" id="exampleInputPassword1" name="_name"/>
                    </div>
                    <div>Your Message</div>
                    <div className="form-floating">

                        <textarea minLength={10} required className="form-control" placeholder=""
                                  id="floatingTextarea" style={{height: "100px"}} name="_textarea"></textarea>
                        <label htmlFor="floatingTextarea">Please write your message here</label>
                    </div>
                    <div className="text-center mt-3">
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    );
}