import React from 'react';
import './App.css';
import {Header} from "./Components/Header";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Home} from "./Components/Home";
import {AboutPage} from "./Components/AboutPage";
import {WorkPage} from "./Components/WorkPage";
import {ContactPage} from "./Components/ContactPage";

function App() {
    return (

        <BrowserRouter basename="/">
            <div className="App d-flex flex-column">
                <Header/>
                <div className="flex-grow-1">
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/about" element={<AboutPage/>}/>
                        <Route path="/my-work" element={<WorkPage/>}/>
                        <Route path="/contact" element={<ContactPage/>}/>
                    </Routes>
                </div>
            </div>
        </BrowserRouter>
    );
}

export default App;
