import {UncontrolledCarousel} from "reactstrap";

export const WorkPage: React.FC = () => {
    const items = [
        {
            src: "/media/smokebreak.jpg",
            altText: 'Micro Film "Smoke Break"',
            header: "Micro Film \"Smoke Break\"",
            key: 1
        },
        {
            src: "/media/pub.jpg",
            altText: 'Short Film "Medal of Honour"',
            header: 'Short Film "Medal of Honour"',
            key: 2
        },
        {
            src: "/media/treeforest.jpg",
            altText: 'Nature Photography',
            header: 'Nature Photography',
            key: 3
        },
        {
            src: "/media/podcast.jpg",
            altText: 'Meal Deal Podcast',
            header: 'Meal Deal Podcast',
            key: 4
        },
        {
            src: "/media/forestwalk.jpg",
            altText: 'Holiday Video Edit',
            header: 'Holiday Video Edit',
            key: 5
        },
        {
            src: "/media/lonelypeg.jpg",
            altText: 'Photography "Lonely Peg"',
            header: 'Photography "Lonely Peg"',
            key: 6
        },
        {
            src: "/media/moon.jpg",
            altText: 'Astrophotography',
            header: 'Astrophotography',
            key: 7
        },
        {
            src: "/media/social_talks.png",
            altText: 'The Social Talks: Intern Editor',
            header: 'The Social Talks: Intern Editor',
            key: 8
        },
        {
            src: "/media/walksuit.jpg",
            altText: 'Short Film "Medal of Honour"',
            header: 'Short Film "Medal of Honour"',
            key: 9
        },
        {
            src: "/media/trees.jpg",
            altText: 'Short Film "Edge of the Bridge"',
            header: 'Short Film "Edge of the Bridge"',
            key: 10
        }
    ];

    return (
        <div className="container pt-5">
            <UncontrolledCarousel items={items} autoPlay={false}/>
        </div>
    )

}