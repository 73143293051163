import ReactCountryFlag from "react-country-flag"

export const AboutPage: React.FC = () => {
    return (<>
        <div className="container text-light mt-5">
            <div className="row">
                <div className="d-flex justify-content-center mb-2">
                    <img className="about-picture" src="/jacob_kryk.jpg"/>
                </div>
                <h1 className="text-center">About me</h1>
                <hr/>
                <div>
                    <p>
                        I am an aspiring Video Editor, I have lived in the UK since 2007 and am a native speaker of both
                        <ReactCountryFlag countryCode="gb" svg/>English and <ReactCountryFlag countryCode="pl"
                                                                                              svg/> Polish, I am
                        currently studying <ReactCountryFlag countryCode="de" svg/> German , <ReactCountryFlag
                        countryCode="jp" svg/>Japanese ,<ReactCountryFlag countryCode="mk" svg/> Macedonian  and
                        <ReactCountryFlag countryCode="nl" svg/> Dutch.
                    </p>
                    <p>
                        I like to engage myself in creative practices such as drawing, playing the piano and guitar. I
                        am interested in creating art through the editing medium.
                    </p>
                    <p>
                        I have many interests but once I become passionate, I delve deep into whatever catches my
                        attention. Thanks to this I can quickly learn and adapt, picking up new skills quickly. After
                        working part time at a Primary School, I decided to expand my horizons and apply myself to
                        something new where I can further develop my skills and grow as an individual and as part of a
                        team.
                    </p>
                    <h6>Studies</h6>
                    <p>
                        I graduated from the University of Winchester with a degree in Film Production and am now
                        seeking
                        opportunities to grow my expertise in editing short films, music videos and any media that comes
                        my
                        way.
                    </p>
                </div>
            </div>
        </div>
    </>)
}