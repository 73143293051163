import React, {useEffect, useState} from 'react';
import {Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink,} from 'reactstrap';
import {Link, useLocation} from "react-router-dom";

export const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const location = useLocation();

    useEffect(() => {
        setIsOpen(false);
    }, [location])

    return (
        <Navbar expand="md" color="dark" dark={true} fixed="top" container="fluid">
            <NavbarBrand tag={Link} to="/">Jacob Kryk</NavbarBrand>
            <NavbarToggler onClick={toggle}/>

            <Collapse isOpen={isOpen} navbar className="justify-content-end">
                <Nav navbar>
                    <NavItem>
                        <NavLink tag={Link} to="/" className="text-end">Home</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={Link} to="/my-work" className="text-end">My work</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={Link} to="/about/" className="text-end">About</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={Link} to="/contact" className="text-end">Contact me</NavLink>
                    </NavItem>
                </Nav>
            </Collapse>
        </Navbar>
    );
}